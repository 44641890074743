<div >
	<mat-action-list class="container-quick" [disableRipple]="false" >
	@for (client of topResults(); track client.client_id){
		
		@if (client.client_id == null){
			<mat-list-item>
				<p matListItemLine>No Results Founds</p>
			</mat-list-item>
		}
		@if (client.client_id !== null){
			<mat-list-item (click)="searchBarService.openClientDetail(client.client_id)">
				<h3 matListItemTitle>{{client.first_name}} {{client.last_name}}</h3>
				<p matListItemLine [innerHTML]="">{{client.mobile}}&nbsp;&nbsp;  {{client.address}}&nbsp;&nbsp;  {{client.email}} </p>
			</mat-list-item>
		}
	}
	
	<mat-divider />
	<!-- <mat-list-item (click)="click()">
		<mat-icon matListItemIcon>search</mat-icon>
		<h3 matListItemTitle>All search results for "{{ searchTerm() }}"</h3>
		<span matListItemMeta  >Press ENTER</span>
	</mat-list-item> -->
</mat-action-list>
</div>