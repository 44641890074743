import { Component, computed } from '@angular/core';
import {MatDivider} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {SearchBarService} from '@services/search-bar.service';
import {QuickResultsListComponent} from '@components/quick-results-list/quick-results-list.component';
import {RecentSearchesListComponent} from '@components/recent-searches-list/recent-searches-list.component';


@Component({
  selector: 'app-search-overlay',
  standalone: true,
  imports: [MatDivider, MatListModule, MatIcon, MatIconButton, QuickResultsListComponent, RecentSearchesListComponent],
  templateUrl: './search-overlay.component.html',
  styleUrl: './search-overlay.component.scss'
})
export class SearchOverlayComponent {
    constructor(
        private searchBarService: SearchBarService

    ) {}
    searchTerm = this.searchBarService.searchTerm;
    recentSearches = computed(() => this.searchBarService.recentSearches().slice(0,3));

    deleteRecentSearch(searchTerm: string){
        this.searchBarService.deleteRecentSearch(searchTerm);

    }

    performSearch(searchTerm: string){
        this.searchBarService.search(searchTerm);
    }
}
