<div class="search-bar-container" 
	 cdkOverlayOrigin 
	 #overlayPosition="cdkOverlayOrigin"
	 [ngClass]="{'opened mat-elevation-z2':overlayOpen()}">
	<button mat-icon-button>
		<mat-icon>search</mat-icon>
	</button>
	<input #searchInput
		   [value]="searchTerm()"
		   (input)="searchTerm.set(searchInput.value)"
		   placeholder="Search Clients" 
		   (click)="overlayOpen.set(true);"
		   (keydown.Enter)="search(searchInput.value)">
	@if (searchTerm()){
		<button class="close-button" mat-icon-button (click)="clearSearch()">
			<mat-icon>close</mat-icon>
		</button>
		<!-- test -->
	}
</div>
<ng-template 
cdkConnectedOverlay 
[cdkConnectedOverlayOrigin]="overlayPosition" 
[cdkConnectedOverlayOpen]="overlayOpen()"
(overlayOutsideClick)="overlayOpen.set(false)">
	<app-search-overlay/>
</ng-template>