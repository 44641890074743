<div class="overlay-container mat-elevation-z2">
	@if (searchTerm()){
		<mat-divider />
		<app-quick-results-list />
	}
	@if (!searchTerm()){
		<!-- <mat-divider /> -->
	<mat-action-list>
		@for (search of recentSearches(); track $index) {
			<mat-list-item (click)="performSearch(search)">
				<mat-icon matListItemIcon>history</mat-icon>
				<h3 matListItemTitle>{{search}}</h3>
				<button matListItemMeta mat-icon-button (click)="deleteRecentSearch(search)">
					<mat-icon>close</mat-icon>
				</button>
			</mat-list-item>
		}
	</mat-action-list>
	}
	
	<!-- <mat-divider /> -->
	<!-- <app-recent-searches-list/> -->
	
</div>
