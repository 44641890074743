
  <!-- Content Wrapper. Contains page content -->
  <!-- <div class="content-wrapper" style="font-size:15px"> -->
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <!-- <div class="container-fluid">
        <h2 class="text-center display-6" >Search Client</h2>
      </div> -->
      <!-- /.container-fluid -->
        <mat-toolbar>
                <app-search-bar></app-search-bar>
        </mat-toolbar>
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <!-- <div class="row mt-3" style=""> -->
                <!-- <div class="col-md-10 offset-md-1"> -->
                    <div class="list-group">
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col px-4">
                                    <div>
                                        <div class="float-right">
                                        {{now | date:'ccc, MMM d, y'}} at {{now | date:'mediumTime'}}</div><br><br>
                                        <h4>{{ client.first_name }} {{ client.last_name }}</h4>
                                        <!-- <p class="mb-0">Name: GUEST </p> -->
                                        <p class="mb-0"><b>Mobile:</b> {{ client.mobile }}</p>
                                        <p class="mb-0"><b>Email:</b> {{ client.email }}</p>
                                        <p class="mb-0"><b>Address:</b> {{ client.address }}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </section>
  <!-- </div> -->
