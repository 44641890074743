import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
// import { ApiService } from '../services/api.service';
import {ApiService} from '@services/api.service';

import { sleep } from '@/utils/helpers';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public users: any = null;
    public client: any = null;



    constructor(private router: Router, public apiService: ApiService, private toastr: ToastrService) {}

    async loginByAuth({username, password}) {
        try {
          console.log('username',username);
          await this.apiService.sendLogin(username,password)     
              .subscribe(
                    (response) => {
                        // console.log('RESPONSE:', response);
                        if(response.isLogin){
                            localStorage.setItem('token',response.secret_key);
                            localStorage.setItem('authentication',
                                JSON.stringify({ profile: 
                                                    { email: username,
                                                      first_name: response.first_name,
                                                      last_name: response.last_name,
                                                      user_id: response.user_id 
                                                    } 
                                                }));
                            // this.getProfile();
                            this.router.navigate(['/clients']);
                            this.toastr.success('Login success');


            // this.toastr.success('Welcome '+response.c_name+'.', 'Success!',);
            //   localStorage.setItem('clientID',response.clientID);
            //   localStorage.setItem('secret_key',response.secret_key);
            //   this.loggedIn = "loggedin";
            //   this.router.navigate(['/dashboard']);





                        }else{
                            response.message = "Invalid Credentials. Account will be blocked if too many attemps";
                            this.toastr.error(response.message);
                        }
                        // Refresh the user list after creating a new user
                        // this.fetchUsers();
                        // this.resetNewUser();
                    },
                    (error) => {
                        console.error(error);
                    }
            );
            
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
          await authLogin(email, password);
          await this.getProfile();
          this.router.navigate(['/']);
          this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
          this.toastr.warning('Not implemented');

        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {

            this.toastr.warning('Not implemented');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    

    async getProfile() {
        try {
            await this.apiService.checkAuth()     
              .subscribe((response) => {
                    // console.log('check auth RESPONSE:', response.data);
                    if(response.isLogin){
                        this.toastr.success('Welcome: '+response.first_name+' '+response.last_name);
                        let authentication = localStorage.getItem('authentication');
                        authentication = JSON.parse(authentication);
                        let data = {
                            profile: authentication,
                            data: response.data
                        }
                        // console.log('test',data);

                        this.user = data;
                        // console.log('user with data::::',this.user);

                    }else{
                        this.toastr.error(response.status+' : '+response.response);
                        this.logout();
                    }
                },(error) => {
                    this.logout();
                    throw error;
                }
            );
            
        const user = await getAuthStatus();
        // console.log('user after authentication:',user);

        if(user) {
              // this.user = this.user;
        // console.log('user after authentication:',this.user);

        } else {
              this.logout();
         }
          } catch (error) {
          this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('authentication');
        this.user = null;
        this.router.navigate(['/login']);
    }
    async getClients(value) {
        try {
            // console.log('value:',value);
            // value = 'k';
            await this.apiService.fetchClients(value)     
              .subscribe((response) => {
                    console.log('RESPONSE result:', response.results);
                    this.users = response.results;
                    console.log('users inside fetch API',this.users);

                },(error) => {
                    // this.logout();
                    this.toastr.error('Could not fetch clients.');

                    throw error;
                }
            );
            
        const users = await getAuthStatus();
        // console.log('users after gt authstatus:',users);

        if(users) {
              // this.users = users;
        console.log('users after gt authstatus:',this.users);

        } else {
              this.logout();
         }


          } catch (error) {
          this.logout();
            throw error;
        }
    }
    async getClientById(value) {
        try {
            // console.log('value:',value);
            // value = 'k';
            await this.apiService.fetchClientById(value)     
              .subscribe((response) => {
                    console.log('RESPONSE result:', response.results);
                    this.client = response.results[0];
                    console.log('users inside fetch API',this.client);

                },(error) => {
                    // this.logout();
                    this.toastr.error('Could not fetch clients.');

                    throw error;
                }
            );
            
        const users = await getAuthStatus();
        // console.log('users after gt authstatus:',users);

        if(users) {
              // this.users = users;
        console.log('users after gt authstatus:',this.users);

        } else {
              this.logout();
         }


          } catch (error) {
          this.logout();
            throw error;
        }
    }
}


export const authLogin = (email: string, password: string) => {
  return new Promise(async (res, rej) => {
    await sleep(500);
          console.log('email',email)
            //  this.apiService.sendLogin(email,password)
            //     .subscribe(
            //         (response) => {
            //             console.log('RESPONSE:', response);
            //             if(response.isLogin){

            //             }else{

            //             }
            //             // Refresh the user list after creating a new user
            //             // this.fetchUsers();
            //             // this.resetNewUser();
            //         },
            //         (error) => {
            //             console.error(error);
            //         }
            // );
    if (email === 'nigelgongoraaaaa@gmail.com' && password === 'admin') {
      localStorage.setItem(
        'authentication',
        JSON.stringify({ profile: { email: 'admin@example.com' } })
      );
      return res({ profile: { email: 'admin@example.com' } });
    }
    return rej({ message: 'Credentials are wrong!' });
  });
};

// handleResponse(response: any) { 
    
//         if(response.success){
//                 console.log("success",response);
//               // this.toastr.success('Welcome '+response.c_name+'.', 'Success!',);
//               // localStorage.setItem('clientID',response.clientID);
//               // localStorage.setItem('secret_key',response.secret_key);
//               // this.loggedIn = "loggedin";
//               // this.router.navigate(['/dashboard']);
//         } else if (response.error){
//               //   this.username = "";
//               //   this.password = "";
//               // this.isLogin = true;
//               console.log("error",response);

//               // this.toastr.error('Username or Password incorrect.', 'Error!');
//         } else {

//         }
    
//   }


export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    try {
      let authentication = localStorage.getItem('authentication');
      if (authentication) {
        authentication = JSON.parse(authentication);
        // console.log('authenticated', authentication);
        return res(authentication);
      }else{
        console.log('NOT authenticated');

      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};
