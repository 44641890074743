import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MENU} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {Dropdown} from '@profabric/angular-components';

@Component({
    selector: 'app-sidebar-search',
    templateUrl: './sidebar-search.component.html',
    styleUrls: ['./sidebar-search.component.scss']
})
export class SidebarSearchComponent {
    public searchText: string = '';
    public foundMenuItems = [];
    public dropdown = {'isOpen': false};


    constructor() {}

    ngOnInit(): void {
    // this.dropdown.isOpen =true;

    }

    handleSearchTextChange(event) {
        this.foundMenuItems = [];

        if (event.target.value) {
            this.searchText = event.target.value;
            this.findMenuItems(MENU);
            return;
        } else {
            this.searchText = '';
            this.dropdown.isOpen = false;
        }
    }

    handleIconClick() {
        this.searchText = '';
        this.dropdown.isOpen = false;
    }

    handleMenuItemClick() {
        this.searchText = '';
        this.dropdown.isOpen = false;
    }

    findMenuItems(menu) {
        if (!this.searchText) {
            return;
        }
        console.log('menu',menu);
        console.log('value',this.searchText);

        menu.forEach((menuItem) => {
        console.log('menuitem',menuItem.name.toLowerCase().includes(this.searchText.toLowerCase()));
        console.log('menupath',menuItem.path);


            if (menuItem.name.toLowerCase().includes(this.searchText.toLowerCase()))
             {
                console.log('inside push');

                this.foundMenuItems.push(menuItem);
            }
            if (menuItem.children) {
                return this.findMenuItems(menuItem.children);
            }
        });

        if (this.foundMenuItems.length > 0) {
            this.dropdown.isOpen = true;
        }
    }

    boldString(str, substr) {
        return str.replaceAll(
            this.capitalizeFirstLetter(substr),
            `<strong class="text-light">${this.capitalizeFirstLetter(
                substr
            )}</strong>`
        );
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
