import { Component, Inject, Injectable } from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {OverlayModule} from '@angular/cdk/overlay';
import {SearchBarService} from '@services/search-bar.service';
import {SearchOverlayComponent} from '@components/search-overlay/search-overlay.component';
import {NgClass} from '@angular/common';


@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [MatIconButton,MatIcon, OverlayModule, SearchOverlayComponent, NgClass],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss'
})
export class SearchBarComponent {


    constructor(
        private searchBarService: SearchBarService

    ) {}

  overlayOpen = this.searchBarService.overlayOpen;
  searchTerm = this.searchBarService.searchTerm;
  
  search(searchTerm: string){
    if(!searchTerm) return;
    this.searchBarService.search(searchTerm);
  }

  clearSearch(){
    this.searchBarService.clearSearch();
  }
}
