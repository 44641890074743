import { Component } from '@angular/core';

@Component({
  selector: 'app-recent-searches-list',
  standalone: true,
  imports: [],
  templateUrl: './recent-searches-list.component.html',
  styleUrl: './recent-searches-list.component.scss'
})
export class RecentSearchesListComponent {

}
