// import { Component } from '@angular/core';
import $ from "jquery";
import "select2";
import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-clients',
  // standalone: true,
  // imports: [MatToolbar, SearchBarComponent],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent implements OnInit, OnDestroy{
  // @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    public searchValue = "";
    public search = "";
    public lastValue = "";
    public lastText = "";
    public users;
    public client;
    public date;
    public now: string;
    // public client: any = null;





    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router

    ) {
            this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)  
          ).subscribe((event: NavigationEnd) => {
            console.log("url change detectedssss",event.url);
                if(event.url == "/clients"){
                    this.client = {};
                    this.client.first_name = 'GUEST';
                    this.client.mobile = '6153918';
                    this.client.email = 'nkonlinebz@gmail.com';
                    this.client.address = 'Starstoon Street, Trial Farm';
                }else{
                    this.route.children.forEach(child => {
            child.params.subscribe(params => {
                const id = params['id']
                console.log('id: ',id); //log the value of id
                this.searchClientById(id);

            })
        })

                }
            })

           setInterval(() => {
              this.now = new Date().toString();
            }, 1);
    }

    ngOnInit() {

            
        this.client = {};
        this.client.first_name = 'GUEST';
        this.client.mobile = '6153918';
        this.client.email = 'nkonlinebz@gmail.com';
        this.client.address = 'Starstoon Street, Trial Farm';
    
          // });
        

    
        this.route.children.forEach(child => {
            child.params.subscribe(params => {
                const id = params['id']
                console.log('id: ',id); //log the value of id
                this.searchClientById(id);

            })
        })

    }

    ngAfterViewInit() {    }

    async searchClients() {
        console.log('searching');
        // if (this.appService.users) {
        //   console.log('USERS FOUND',this.appService.users);
        //     return true;
        // }

        try {
            await this.appService.getClients(this.searchValue);
            this.users = this.appService.users;
       
           // this.users = this.appService.users;
            console.log('users inside client',this.users);
            return true;
        } catch (error) {
            return false;
        }
    }
    async searchClientById(value){
        try {
            await this.appService.getClientById(value);
            this.client = this.appService.client;
            console.log('users inside client async',this.client);
            return true;

        } catch (error) {
            return false;
        }
    }
    async searchClient(value) {
        console.log('searching');
        // if (this.appService.users) {
        //   console.log('USERS FOUND',this.appService.users);
        //     return true;
        // }

        try {
            await this.appService.getClients(value);
            this.users = this.appService.users;
       
           // this.users = this.appService.users;
            console.log('users inside clientssss :::',this.users);
            return true;
        } catch (error) {
            return false;
        }
    }
    clearSearch(){

    }
    ngOnDestroy() {
        // this.renderer.removeClass(
        //     document.querySelector('app-root'),
        //     'login-page'
        // );
    }

}
