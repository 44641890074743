import { Injectable, signal, effect } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, filter } from 'rxjs';
import {ApiService} from '@services/api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

export interface Client {
    client_id: string;
    first_name: string;
    last_name: string;
    mobile: string;
    address: string;
    email: string;
}

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {

  overlayOpen = signal(false);
  recentSearches = signal<string[]>(JSON.parse(window.localStorage.getItem('recentSearches') ?? '[]'));
  quickResults = signal<Client[]>([]);

    // "kaisley gongora",
    // "nigel gongora jr.",
    // "kirstie gongora",
    // "nigel gongora sr.",

  searchTerm = signal('');

  constructor(private router: Router, public apiService: ApiService, private toastr: ToastrService) {
      toObservable(this.searchTerm)
      .pipe(debounceTime(500))
      .subscribe((term) => {
              if (!term){
            this.quickResults.set([]);
            return;
        }
        this.apiService.fetchClients(term).subscribe((response) => {
                    // console.log('RESPONSESSSSS result:', response.results);
                    // this.users = response.results;
                    // console.log('users inside fetch API',this.users);
                    if(response.results.length == 0){
                      // console.log("no result");
                        response.results[0] = {};
                        response.results[0].client_id = null;
                        response.results[0].first_name = "No Results Found";
                    }
                    this.quickResults.set(convertToResults(response.results));

                },(error) => {
                    // this.logout();
                    // this.toastr.error('Could not fetch clients.');
                    throw error;
                }
            );
        // this.apiService.getQuickResults(term);

      })

  }
  openClientDetail(client_id: string){
    //Perform the Search
    // this.searchTerm.set(searchTerm);
    this.overlayOpen.set(false);
    // var route = '/clients/'+client_id;
    // console.log(route);
    this.router.navigate(['/clients/'+client_id]);

    // this.addToRecentSearches(searchTerm);
  }

  search(searchTerm: string){
    //Perform the Search
    this.searchTerm.set(searchTerm);
    // this.overlayOpen.set(false);
    this.addToRecentSearches(searchTerm);
  }

  clearSearch(){
    //CLEAR SEARCH RESULTS
    this.searchTerm.set('');
    this.overlayOpen.set(true);
  }
  
  addToRecentSearches(searchTerm: string){
    const lowerCaseTerm = searchTerm.toLowerCase();
    this.recentSearches.set([lowerCaseTerm, 
                             ...this.recentSearches().filter((s) => s !== lowerCaseTerm),
                            ]);
  }

  deleteRecentSearch(searchTerm: string){
    this.recentSearches.set(this.recentSearches().filter(s => s !== searchTerm));
  }

  saveLocalStorage = effect(() => {
    window.localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches()));
  });

}

export const convertToResults = (data:any[]): Client[]=>
data.map(
({
    address,
    client_id,
    date_created,
    email,
    first_name,
    id,
    last_name,
    mobile,
    password,
}:any) => ({
    client_id: client_id,
    first_name: first_name,
    last_name: last_name,
    mobile: mobile,
    address: address,
    email: email,
}));