import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Client {
    client_id: string;
    first_name: string;
    last_name: string;
    mobile: string;
    address: string;
    email: string;
}
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    quickResults = signal<Client[]>([]);
    public data: any = null;
    private _loginUrl = 'https://www.nkerpapi.nkonlinebz.com/api/index.php/main/loginClient';
    private _checkAuthUrl = 'https://www.nkerpapi.nkonlinebz.com/api/index.php/main/checkAuth';
    private _getClientsUrl = 'https://www.nkerpapi.nkonlinebz.com/api/index.php/main/getClients';
    private _getClientByIdUrl = 'https://www.nkerpapi.nkonlinebz.com/api/index.php/main/getClientById';

    private apiUrl = 'https://api.example.com/users'; // Replace with your API endpoint
    
    constructor(private http: HttpClient) {}


    
    sendLogin(username:any,password:any): Observable<any>{
        let body = new HttpParams();
        body = body.append('username', username);
        body = body.append('password', password);
        const headers = new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'});
        return this.http.post<any>(this._loginUrl, body, { headers });
    }

    checkAuth(){
       let key = localStorage.getItem('token') || '{}';
       var header = {headers: new HttpHeaders().set('Authorization',  `Bearer `+key)}

       return this.http.get<any>(this._checkAuthUrl,header);
    }
    fetchClients(value:any){
        // if (!value){
        //     this.quickResults.set([]);
        //     return;
        // }
       let key = localStorage.getItem('token') || '{}';
       var header = {headers: new HttpHeaders().set('Authorization',  `Bearer `+key)}

       return this.http.get<any>(this._getClientsUrl+"?searchValue="+value,header);
    }
    fetchClientById(value:any){
        // if (!value){
        //     this.quickResults.set([]);
        //     return;
        // }
       let key = localStorage.getItem('token') || '{}';
       var header = {headers: new HttpHeaders().set('Authorization',  `Bearer `+key)}

       return this.http.get<any>(this._getClientByIdUrl+"?searchValue="+value,header);
    }
    
    async getQuickResults(searchTerm: string){
        if (!searchTerm){
            this.quickResults.set([]);
            return;
        }

        // const searchTermForQuery = searchTerm.toLowerCase().split(' ').join('+'); 
        let key = localStorage.getItem('token') || '{}';
        var header = {headers: new HttpHeaders().set('Authorization',  `Bearer `+key)}

       const response = await this.http.get<any>(this._getClientsUrl+"?searchValue="+searchTerm+"&maxResults=5=",header);
       console.log('response:',response);
       
       // const data = await response.json();
       // let data = this.http.get<any>(this._getClientsUrl+"?searchValue="+searchTerm+"&maxResults=5=",header);
       // console.log('result:',data);
       // this.quickResults.set(convertToResults(data));
    }

    fetchUsers(): Observable<any[]> {
        return this.http.get<any[]>(this.apiUrl);
    }

    createUser(user: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<any>(this.apiUrl, user, { headers });
    }

    deleteUser(userId: number): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}/${userId}`);
    }

}

export const convertToResults = (data:any[]): Client[]=>
data.map(
({
    address,
    client_id,
    date_created,
    email,
    first_name,
    id,
    last_name,
    mobile,
    password,
}:any) => ({
    client_id: client_id,
    first_name: first_name,
    last_name: last_name,
    mobile: mobile,
    address: address,
    email: email,
}));