import { Component, computed } from '@angular/core';
import {SearchBarService} from '@services/search-bar.service';
import {MatDivider} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'app-quick-results-list',
  standalone: true,
  imports: [MatDivider, MatListModule, MatIcon, MatIconButton],
  templateUrl: './quick-results-list.component.html',
  styleUrl: './quick-results-list.component.scss'
})
export class QuickResultsListComponent {
   constructor(
        public searchBarService: SearchBarService

    ) {}
    searchTerm = this.searchBarService.searchTerm;
    // topResults = this.searchBarService.quickResults;
    topResults = computed(() => this.searchBarService.quickResults().slice(0,6));


    click() {
      console.log(this.topResults);
    }

}
